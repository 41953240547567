<template>
  <div>
    <!--		<agent-wallet-filters-->
    <!--			:name-filter.sync="nameFilter"-->
    <!--			:code-filter.sync="codeFilter"-->
    <!--            :type-filter.sync="typeFilter"-->
    <!--            :type-options="typeOptions"-->
    <!--            :is-maintenance-filter.sync="isMaintenanceFilter"-->
    <!--            :is-maintenance-options="isMaintenanceOptions"-->
    <!--			:status-filter.sync="statusFilter"-->
    <!--			:status-options="statusOptions"-->
    <!--            :agent-filter.sync="agentFilter"-->
    <!--            :agent-lists="agentLists"-->
    <!--		/>-->
    <b-card no-body>
      <b-table
        ref="refAgentTable"
        class="position-relative table-white-space mb-0"
        :items="fetchAgents"
        responsive
        :fields="tableColumns"
        primary-key="index"
        show-empty
        empty-text="No matching records found"
      >
        <template #cell(index)="data">
          {{ perPage * (currentPage - 1) + data.index + 1 }}
        </template>
        <template #cell(wallet_name)="data">
          <b-link
            class="text-white cursor-pointer"
            @click="editAgentWallet(data.item)"
          >
            {{ data.item.wallet_name }}
          </b-link>
        </template>

        <template #cell(is_maintenance)="data">
          {{
            resolveMaintenance(
              data.item.start_maintenance,
              data.item.end_maintenance
            )
          }}
        </template>

        <template #cell(status)="data">
          <div :class="`text-capitalize align-items-center d-flex`">
            <span
              :class="`dot bg-${resolveStatus(data.item.status).variant}`"
            />{{ resolveStatus(data.item.status).label }}
          </div>
        </template>

        <template #cell(action)="data">
          <b-button
            v-if="$can('update','agent')"
            v-b-tooltip.hover.v-info
            :title="$t('Edit')"
            variant="primary"
            class="btn-icon btn-sm mr-50"
            @click="editAgentWallet(data.item)"
          >
            <feather-icon icon="EditIcon" />
          </b-button>
        </template>
      </b-table>
      <div class="p-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            "
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span
            >
          </b-col>
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            "
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalAgent"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <update-Agent-wallet-modal
      :agent-wallet-detail.sync="agentWalletDetail"
      @refetch-data="refetchData"
    />
  </div>
</template>

<script>
import {
  BButton,
  BModal,
  VBModal,
  BAlert,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BTable,
  BTr,
  BTh,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormGroup,
  VBTooltip,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { ref, watch, onUnmounted, computed } from "@vue/composition-api";
import { formatDateTime } from "@core/utils/filter";
import { useToast } from "vue-toastification/composition";
import store from "@/store";
import agentStoreModule from "@/views/agent/agentStoreModule";
import AgentWalletFilters from "./AgentWalletFilters.vue";

export default {
  components: {
    AgentWalletFilters,
    UpdateAgentWalletModal: () => import("./UpdateAgentWalletModal"),
    BButton,
    BFormGroup,
    BModal,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BTr,
    BTh,
    BMedia,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BAlert,
  },
  directives: {
    "b-tooltip": VBTooltip,
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      roleId: null,
      content: null,
      agentWalletDetail: null,
    };
  },
  methods: {
    editAgentWallet(datas) {
      this.agentWalletDetail = datas;
      this.$bvModal.show("modal-update-agent-wallet");
    },
  },
  setup() {
    const AGENT_MODULE_NAME = "agent";
    if (!store.hasModule(AGENT_MODULE_NAME))
      store.registerModule(AGENT_MODULE_NAME, agentStoreModule);
    onUnmounted(() => {
      if (store.hasModule(AGENT_MODULE_NAME))
        store.unregisterModule(AGENT_MODULE_NAME);
    });
    const perPage = ref(25);
    const totalAgent = ref(0);
    const currentPage = ref(1);
    const nameFilter = ref(null);
    const codeFilter = ref(null);
    const typeFilter = ref(null);
    const typeOptions = ref([]);
    const isMaintenanceFilter = ref(null);
    const statusFilter = ref(null);
    const agentFilter = ref(null);
    const agentLists = ref([]);
    const refAgentTable = ref(null);
    const tableColumns = [
      { key: "index", label: "#" },
      { key: "name", label: "Name" },
      { key: "code", label: "Code" },
      { key: "balance_type.wallet_name", label: "Wallet Name" },
      { key: "game_category.name", label: "Agent" },
      { key: "status", label: "Status" },
      // { key: "is_maintenance", label: "Is maintenance" },
      { key: "start_maintenance", label: "Start maintenance" },
      { key: "end_maintenance", label: "End maintenance" },
      { key: "action", label: "Actions" },
    ];

    const refetchData = () => {
      refAgentTable.value.refresh();
    };

    watch(
      [
        currentPage,
        nameFilter,
        codeFilter,
        typeFilter,
        isMaintenanceFilter,
        statusFilter,
        agentFilter,
      ],
      () => {
        refetchData();
      }
    );

    const fetchAgents = (ctx, callback) => {
      store
        .dispatch("agent/fetchAgents", {
          status: statusFilter.value,
          // page: currentPage.value,
        })
        .then((response) => {
          if (response.data.code == "00") {
            callback(response.data.data);
            agentLists.value = response.data.data;
            totalAgent.value = response.data.count;
            perPage.value =
              currentPage.value == 1 ? response.data.data.length : 25;
          }
        });
    };

    const dataMeta = computed(() => {
      const localItemsCount = refAgentTable.value
        ? refAgentTable.value.localItems.length
        : 0;
      return {
        from:
          perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
        to: perPage.value * (currentPage.value - 1) + localItemsCount,
        of: totalAgent.value,
      };
    });

    const statusOptions = [
      { label: "Active", value: 1 },
      { label: "Inactive", value: 2 },
    ];

    const isMaintenanceOptions = [
      { label: "Yes", value: 1 },
      { label: "No", value: 2 },
    ];

    const resolveStatus = (status) => {
      if (status == 1) return { label: "Active", variant: "primary" };
      if (status == 2) return { label: "Inactive", variant: "danger" };
      return { label: "None", variant: "secondary" };
    };

    const resolveMaintenance = (start, end) => {
      const startDate = new Date(start);
      const endDate = new Date(end);
      const now = new Date();
      if (startDate <= now && now <= endDate) return "Yes";
      if (now > endDate) return "No";
      if (now < startDate) return "No";
      return "-";
    };
    return {
      fetchAgents,
      tableColumns,
      perPage,
      currentPage,
      totalAgent,
      dataMeta,
      refAgentTable,
      refetchData,

      nameFilter,
      codeFilter,
      typeFilter,
      typeOptions,
      isMaintenanceFilter,
      isMaintenanceOptions,
      statusFilter,
      statusOptions,
      agentFilter,
      agentLists,
      formatDateTime,
      resolveStatus,
      resolveMaintenance,
    };
  },
};
</script>